import styles from "../css/header.module.css";
import { Link } from "react-router-dom";

const Header = (props) => {
  let titleSponsor= props.title;
  
  return (
    <div className={styles.header}>
      <div className={styles.intro}>
        <div className={styles.heading}>
          
          { 
          (titleSponsor.length>0)? (
          <div className={styles.sponsor}><a
              key={titleSponsor[0].Id}
              href={titleSponsor[0].website}
              target="_blank"
              rel="noreferrer"
            >
            <img className={styles.titleSponsor} src={titleSponsor[0].img.url} alt="pic" />
            </a>
            <p className={styles.codingal}>Codingal Presents</p>
          </div> ): null}
            
          ANTARAGNI'24</div>
        <div className={styles.ca}>CAMPUS AMBASSADOR PROGRAM</div>
        <div className={styles.para}>
          <span className={styles.paratext}>
            Come join us to experience this spectacular cultural festival of IIT
            Kanpur...&nbsp;&nbsp;
            <Link to="/login">
              <button className={styles.join}>JOIN NOW</button>
            </Link>
          </span>
        </div>
      </div>
      <div className={styles.stats}>
        <div className={styles.singleDiv}>
          <div className={styles.grad}>
            <div className={styles.text}>
              <span>70+</span>
            </div>
          </div>
          <div className={styles.name}>COMPETITIONS</div>
        </div>
        <div className={styles.singleDiv}>
          <div
            className={styles.grad}
            style={{ background: "linear-gradient(40deg, red, black)" }}
          >
            <div className={styles.text}>
              <span>350+</span>
            </div>
          </div>
          <div className={styles.name}>COLLEGES</div>
        </div>
        <div className={styles.singleDiv}>
          <div
            className={styles.grad}
            style={{ background: "linear-gradient(160deg, red, black)" }}
          >
            <div className={styles.text}>
              <span>10+</span>
            </div>
          </div>
          <div className={styles.name}>PROFESSIONAL SHOWS</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
