import styles from "../css/sponsor.module.css";

const Sponsors = ({ data1, data2 }) => {
  return (
    <div className={styles.sponsors} id="sponsors">
      <h1>SPONSORS</h1>

      <h2>Campus Outreach Partner</h2>
      {data1.length <= 0 ? (
        <div className={styles.tba}>
          <h1>To be announced...</h1>
          <br />
        </div>
      ) : (
        <div>
          {data1.map((item) => (
            <a
              key={item.Id}
              href={item.website}
              target="_blank"
              rel="noreferrer"
            >
              <img src={item.img.url} alt="pic" />
            </a>
          ))}
        </div>
      )}

      <h2>Goodies Partner</h2>
      {data2.length <= 0 ? (
        <div className={styles.tba}>
          <h1>To be announced...</h1>
          <br />
        </div>
      ) : (
        <div>
          {data2.map((item) => (
            <a
              key={item.Id}
              href={item.website}
              target="_blank"
              rel="noreferrer"
            >
              <img src={item.img.url} alt="pic" />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sponsors;
