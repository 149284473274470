import styles from "../css/tasks.module.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useState, useEffect } from "react";
import { getAllDocs, addData, queryData } from "../config/firebaseFirestore";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../hooks";
import { ShowToast } from "../components";

const TasksPage = () => {
  const auth = useAuth();
  const [tasks, setTasks] = useState([]);
  const getAllTasks = async () => {
    try {
      let allTasks = await getAllDocs("tasksCA");
      let submissions = await queryData(
        "CAsSubmissions24",
        "id",
        auth.user.details.id
      );
      let tasks = [];
      if (submissions != null && allTasks != null) {
        for (let i = 0; i < allTasks.length; i++) {
          let rawTask = allTasks[i];
          let task = {};
          let submission = submissions.find((el) => {
            return el.data.taskId === rawTask.uid;
          });
          if (submission !== undefined) {
            task = {
              uid: rawTask.uid,
              desc: rawTask.data.desc,
              points: rawTask.data.points,
              deadline: rawTask.data.deadline,
              award: submission.data.award,
              link: submission.data.link,
            };
          } else {
            task = {
              uid: rawTask.uid,
              desc: rawTask.data.desc,
              points: rawTask.data.points,
              deadline: rawTask.data.deadline,
              award: undefined,
              link: undefined,
            };
          }
          tasks.push(task);
        }
        setTasks(tasks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTasks();
  }, []);
  return (
    <div className={styles.tasks}>
      <div className={styles.wrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.entry}>
              <th>Description</th>
              <th>Deadline</th>
              <th>Points</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((e) => {
              return <Row data={e} getAllTasks={getAllTasks} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Row = ({ data, getAllTasks }) => {
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const contentStyle = {
    background: "rgba(255, 255, 255, 0.12)",
    borderRadius: "16px",
    boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(4.3px)",
    border: "1px solid rgba(255, 255, 255, 1)",
  };

  const [link, setLink] = useState("");
  // Register Function
  const submit = async (task) => {
    try {
      if (link != "") {
        let data = {
          taskId: task.uid,
          taskDesc: task.desc,
          taskPoints: task.points,
          uid: auth.user.user.uid,
          id: auth.user.details.id,
          name: auth.user.details.name,
          email: auth.user.details.email,
          phone: auth.user.details.phone,
          link: link,
          college: auth.user.details.college,
          collegeCity: auth.user.details.collegeCity,
        };
        addData("CAsSubmissions24", data);
        ShowToast("Task Submitted Successfully ");
        setLink("");
        getAllTasks();
      } else {
        ShowToast("Please Provide the Proof Link !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr className={styles.entry}>
      <td>
        <div className={styles.desc}>{data.desc}</div>
      </td>
      <td>{`${data.deadline.toDate().toDateString()} ${data.deadline
        .toDate()
        .toLocaleTimeString()}`}</td>
      <td>{data.points}</td>
      <td>
        {data.link !== undefined ? (
          data.award !== undefined ? (
            `Points Awarded: ${data.award}`
          ) : (
            "In Review"
          )
        ) : (
          <Popup
            modal={true}
            trigger={
              <button className={styles.btn} onClick={(e) => {}}>
                Submit
              </button>
            }
            {...{ contentStyle }}
            position="center center"
            on="click"
            open={isOpen}
            onOpen={() => {
              setIsOpen(true);
            }}
            onClose={() => {
              setLink("");
            }}
          >
            <div className={styles.submitModal}>
              <div className={styles.textInput}>
                <label className={styles.label} htmlFor="proof">
                  Link of Proof*
                </label>
                <input
                  className={styles.input}
                  id="proof"
                  type="url"
                  required
                  placeholder="Enter Proof Link"
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
              </div>
              <button
                className={styles.btn}
                onClick={(e) => {
                  setIsOpen(false);
                  submit(data);
                }}
              >
                Submit
              </button>
            </div>
          </Popup>
        )}
      </td>
    </tr>
  );
};

export default TasksPage;
