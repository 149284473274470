import styles from "../css/login.module.css";
import { firebaseGoogleSignIn } from "../config/firebaseAuth";
import { DashboardNavbar } from "../components";
const LoginPage = () => {
  return (
    <div className={styles.login}>
      <DashboardNavbar />
      <h1 className={styles.logintitle}>ANTARAGNI'24</h1>
      <div className={styles.cover}>
        <h2 className={styles.logintitle2}>Login</h2>
        <div className={styles.details}>
          <button
            className={styles.glogin}
            onClick={() => {
              firebaseGoogleSignIn();
            }}
          >
            Login With Google
          </button>
          <br />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
