import styles from "../css/navbar.module.css";
import { Link } from "react-router-dom";
import antLogo from "../assets/logo1.png";
import { useAuth } from "../hooks";

const LandingPageNavbar = () => {
  const auth = useAuth();
  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <Link to="/">
          <img src={antLogo} width={30} alt="picc" />
        </Link>
      </div>
      <div className={styles.rightOuterDiv}>
        <ul className={styles.right}>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#whyca">Why CA</a>
          </li>
          <li>
            <a href="#incentives">Incentives</a>
          </li>
          <li>
            <a href="#responsibilities">Responsibilites</a>
          </li>
          <li>
            <a href="#faq">FAQ</a>
          </li>
          <li>
            <a href="#contacts">Contacts</a>
          </li>
          <li>
            <a href="#sponsors">Sponsors</a>
          </li>
        </ul>
        {!auth.user ? (
          <Link to="/login">
            <div className={styles.loginBtn}>Login</div>
          </Link>
        ) : (
          <Link to="/dashboard">
            <div className={styles.loginBtn}>Dashboard</div>
          </Link>
        )}
      </div>
    </div>
  );
};

const DashboardNavbar = () => {
  const auth = useAuth();
  return (
    <div
      className={styles.navbar}
      style={{
        background: "linear-gradient(to right, #434343 0%, black 100%)",
      }}
    >
      <div className={styles.left}>
        <Link to="/">
          <img src={antLogo} width={30} height={50} alt="picc" />
        </Link>
      </div>
      <div className={styles.rightOuterDiv}>
        {/* <ul className={styles.right}>
          <li>
            <Link to="/dashboard/profile">My Profile</Link>
          </li>
          <li>
            <Link to="/dashboard/leaderboard">Leaderboard</Link>
          </li>
          <li>
            <Link to="/dashboard/tasks">My Tasks</Link>
          </li>
          <li>
            <Link to="/dashboard/ideas">Ideas</Link>
          </li>
        </ul> */}
        {auth.user ? (
          <>
            <Link to="/">
              <div className={styles.loginBtn}>Home</div>
            </Link>
            <div
              className={styles.loginBtn}
              onClick={() => {
                auth.logout();
              }}
            >
              Logout
            </div>
          </>
        ) : (
          <Link to="/">
            <div className={styles.loginBtn}>Home</div>
          </Link>
        )}
      </div>
    </div>
  );
};

export { LandingPageNavbar, DashboardNavbar };
