import styles from "../css/leaderboard.module.css";
import { useState, useEffect } from "react";
import {
  getAllDocs,
  addData,
  getSortedData,
} from "../config/firebaseFirestore";

const LeaderboardPage = () => {
  const [CAs, setCAs] = useState([]);
  const getAllCAs = async () => {
    try {
      let data = await getSortedData("CAs24", "points", 20);
      console.log(data);
      if (data != null) {
        setCAs(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCAs();
  }, []);
  return (
    <div className={styles.leaderboard}>
      <div className={styles.wrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.entry}>
              <th>Rank</th>
              <th>CA Id</th>
              <th>Name</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {CAs.map((e, i) => {
              return (
                <tr className={styles.entry}>
                  <td>{i + 1}</td>
                  <td>{e.data.id}</td>
                  <td>{e.data.name}</td>
                  <td>{e.data.points}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderboardPage;
