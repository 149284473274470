import { useState, useEffect } from "react";
import { LandingPageNavbar } from "../components";
import {
  Header,
  AboutUs,
  WhyCA,
  Incentives,
  Expectation,
  FAQ,
  ContactUs,
  Sponsors,
} from "../pages";
import { getSingleDoc } from "../config/firebaseFirestore";

const LandingPage = () => {
  const [contacts, setContacts] = useState([]);
  const [outreachPartners, setOutreachPartners] = useState([]);
  const [goodiesPartners, setGoodiesPartners] = useState([]);
  const [titleSponsor,setTitleSponsor] = useState([]);

  const fetchData = async () => {
    const contactData = await getSingleDoc("WebContentsNew", "NewQueries");
    let contacts = [];

    for (let i = 0; i < contactData.data.length; i++) {
      switch (contactData.data[i].Dept.content) {
        case "Hospitality and Transport":
          contacts.push(contactData.data[i]);
          break;
        default:
          console.log("Unknown Flag");
          break;
      }
    }

    const sponsorData = await getSingleDoc("WebContents", "CAportal");
    let outreachPartners = [];
    let goodiesPartners = [];
    let titleSponsor = [];
    
    for (let i = 0; i < sponsorData.data.length; i++) {
      
      switch (sponsorData.data[i].flag) {
        case "outreachPartner":
          outreachPartners.push(sponsorData.data[i]);
          break;
        case "goodiesPartner":
          goodiesPartners.push(sponsorData.data[i]);
          break;
        case "titleSponsor" :
          titleSponsor.push(sponsorData.data[i]);
          break;
        default:
          console.log("Unknown Flag");
          break;
      }
    }

    setTitleSponsor(titleSponsor);
    setContacts(contacts);
    setOutreachPartners(outreachPartners);
    setGoodiesPartners(goodiesPartners);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <LandingPageNavbar />
      <Header title={titleSponsor}/>
      <AboutUs />
      <WhyCA />
      <Incentives />
      <Expectation />
      <FAQ />
      <ContactUs data={contacts} />
      <Sponsors data1={outreachPartners} data2={goodiesPartners}/>
    </div>
  );
};

export default LandingPage;
