import React from "react";
import styles from "../css/contactcard.module.css";
import { GrFacebook } from "react-icons/gr";
import { BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";

function ContactCard({ contact }) {
  const { Pic, Name, Post, Dept, Contact, Email, Facebook, LinkedIn, Instagram } = contact;

  return (
    <div className={styles.card}>
      <img src={Pic.content.url} className={styles.profile} alt="organizer" />
      <div className={styles.curve}></div>
      <h3 className={styles.profilename}>{Name.content}</h3>
      <p className={styles.about}>
        {Post.content}
        <br />
        {Dept.content}
        <br />
        <a href={`tel:${Contact.content}`} target="_blank" rel="noreferrer">
          Mobile: {Contact.content}
        </a>
      </p>

      <div className={styles.socialLinks}>
        <a href={`mailto:${Email.content}`} target="_blank" rel="noreferrer">
          <MdEmail size={33} />
        </a>
        <a href={Facebook.content} target="_blank" rel="noreferrer">
          <GrFacebook size={25} />
        </a>
        <a href={LinkedIn.content} target="_blank" rel="noreferrer">
          <BsLinkedin size={25} />
        </a>
        <a href={Instagram.content} target="_blank" rel="noreferrer">
          <FaInstagram size={25} />
        </a>
      </div>
    </div>
  );
}

export default ContactCard;
