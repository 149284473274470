import images from "../assets";

const faqDB = [
  {
    question: "What is the Campus Ambassador Program?",
    answer:
      "The Campus Ambassador Program is a student-driven initiative where selected ambassadors promote our brand, organize events, and provide feedback from their respective campuses. This program aims to create a strong community and enhance brand awareness among students.",
    open: true,
  },
  {
    question: "Who can apply for the Campus Ambassador Program?",
    answer:
      "Any currently enrolled university or college student with good communication skills, an active social media presence, and a passion for Antaragni can apply.",
    open: false,
  },
  {
    question: "How do I apply for the program?",
    answer:
      "You can apply by registering on our website. The application process requires just submitting your details.",
    open: false,
  },
  {
    question: "What are the responsibilities of a Campus Ambassador?",
    answer:
      "Campus Ambassadors promote Antaragni, organize events, provide feedback, create content, and network with other students to enhance engagement and awareness.",
    open: false,
  },
  {
    question: "What benefits do Campus Ambassadors receive?",
    answer:
      "Ambassadors gain professional development experience, exclusive access to products and events, mentorship, networking opportunities, certificates of recognition, and various incentives like merchandise and gift cards.",
    open: false,
  },
  {
    question: "How is the points system structured?",
    answer:
      'The points system, "Engagement Tokens," rewards ambassadors for their activities and contributions. Points can be earned through various tasks such as event organization, social media promotion, and content creation.',
    open: false,
  },
  {
    question: "What is the level system in the program?",
    answer:
      'The level system, referred to as "Achievement Tiers," signifies the progression of ambassadors based on their points and contributions. It ranges from initial stages like "Ambassador Apprentice" to advanced stages like "Ambassador Legend."',
    open: false,
  },
  {
    question: "How long does the program last?",
    answer:
      "The Campus Ambassador Program typically lasts for 6 months, with the possibility of extension based on performance and mutual interest.",
    open: false,
  },
  {
    question: "Can I apply if my university is not listed?",
    answer:
      "Yes, you can still apply. We welcome applications from students from all universities and colleges.",
    open: false,
  },
  {
    question: "How will I know if I am selected?",
    answer:
      "Once registered on the portal, you can start doing the task and join the Antaragni Outreach Team.",
    open: false,
  },
  {
    question: "What kind of events am I expected to organize?",
    answer:
      "Events can include workshops, seminars, webinars, social gatherings, and promotional activities tailored to your campus's interests and needs.",
    open: false,
  },
  {
    question: "How do I track my progress and points?",
    answer:
      "You can track your progress by Leaderboard and Dashboard through our website, where all activities and points are logged and updated.",
    open: false,
  },
  {
    question: "What support will I receive as a Campus Ambassador?",
    answer:
      "You will receive guidance and a Roadmap from our team.",
    open: false,
  },
  {
    question: "What happens if I cannot fulfill my duties?",
    answer:
      "If you need help in fulfilling your duties, please contact our team as soon as possible. We understand that academic commitments are a priority and can discuss possible solutions or adjustments to your responsibilities.",
    open: false,
  },
];

const expectationDB = [
  {
    id: 1,
    img: images.expect1,
    title: "ADVERTISE",
    desc: " Publicize Antaragni and its sponsors in your college by sharing posts by becoming a focal point for your respective college ",
  },
  {
    id: 2,
    img: images.expect2,
    title: "ORGANISE",
    desc: " Organize events, workshops and sessions regarding Antaragni and what it has to offer with assistance from mentors ",
  },
  {
    id: 3,
    img: images.expect3,
    title: "CONDUCT",
    desc: " Help in management of elimination rounds in your college and city to select participants for the main event ",
  },
  {
    id: 4,
    img: images.expect4,
    title: "COORDINATE",
    desc: "Collaborate with the Antaragni team in organising on-ground events in your city ",
  },
];

const incentivesDB = [
  {
    id: 1,
    img: images.incentive1,
    title: "CERTIFICATION",
    desc: " Certificate of appreciation from Antaragni’23, IIT Kanpur recognizing your hardwork ",
  },
  {
    id: 2,
    img: images.incentive2,
    title: "PRONITES",
    desc: " Free entry for the top performing campus ambassadors in exclusive Pronites @Antaragni'23 ",
  },
  {
    id: 3,
    img: images.incentive3,
    title: "OPPORTUNITIES",
    desc: " Top performing campus ambassadors will get a chance to gain professional experience through insternship provided by top companies ",
  },
  {
    id: 4,
    img: images.incentive4,
    title: "GOODIES & MERCHANDISE",
    desc: " Win Antaragni merchandise, promising rewards, gift vouchers from top brands and much more! ",
  },
];

const whycaDB = [
  {
    id: 1,
    img: images.whyca1,
    title: "BE A LEADER",
    desc: " Represent your college as you help organize one of Asia's largest cultural fests ",
  },
  {
    id: 2,
    img: images.whyca2,
    title: "NETWORKING",
    desc: " Get opportunities to interact with celebrities from various fields ",
  },
  {
    id: 3,
    img: images.whyca3,
    title: "ENHANCE YOUR SKILLS",
    desc: " Improve your communication and managerial abilities ",
  },
  {
    id: 4,
    img: images.whyca4,
    title: "FREE COURSES",
    desc: " Top performing CAs to get access to Online courses and an earning opportunity based on performance ",
  },
];

const data = {
  faqDB,
  expectationDB,
  incentivesDB,
  whycaDB,
};

export default data;
