import styles from "../css/register.module.css";
import btnStyles from "../css/button.module.css";
import { useAuth } from "../hooks";
import { useState } from "react";
import ShortUniqueId from "short-unique-id";
import { queryData, updateData } from "../config/firebaseFirestore";
import { firebaseLinkPhone, firebaseVerifyOTP } from "../config/firebaseAuth";
import { ShowToast } from "../components";
import { DashboardNavbar } from "../components";
const Register = () => {
  const auth = useAuth();
  const uid = new ShortUniqueId({
    length: 10,
    dictionary: "alphanum_upper",
  });
  const [name, setName] = useState(auth.user.user.displayName.toUpperCase());
  const [email /* setEmail */] = useState(auth.user.user.email);
  const [address, setAddress] = useState("");
  const [college, setCollege] = useState("");
  const [collegeCity, setCollegeCity] = useState("");
  const [yos, setYos] = useState("");
  const [gender, setGender] = useState("");
  const [wa, setWa] = useState("");
  const [mobile, setMobile] = useState(
    auth.user.user.phoneNumber !== null
      ? auth.user.user.phoneNumber.slice(3)
      : ""
  );
  const [fb, setfb] = useState("");
  const [insta, setInsta] = useState("");
  const [twitter, setTwitter] = useState("");
  const [referrer, setReferrer] = useState("");
  // const [otp, setOtp] = useState("");
  // const [sendingOtp, setSendingOtp] = useState(false);
  // const [phoneVerified, setPhoneVerified] = useState(
  //   auth.user.user.phoneNumber !== null
  // );

  // function to add referrer points to referrer CA
  const awardPoint = async () => {
    try {
      let rawUsers = await queryData("CAs24", "id", referrer);
      if (rawUsers.length > 0) {
        let user = rawUsers[0];
        let points = user.data.points;
        points += 10;
        await updateData("CAs24", user.uid, { points: points });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Register Function
  const register = async () => {
    try {
      if (mobile.length !== 10 || wa.length !== 10) {
        ShowToast("Please enter 10 digit Mobile / Whatsapp Number !");
        return;
      }

      if (
        mobile != "" &&
        address !== "" &&
        college !== null &&
        college !== "" &&
        collegeCity !== "" &&
        yos !== "" &&
        gender !== "" &&
        wa !== ""
      ) {
        let UserData = {
          id: `CA.ANT.${uid()}`,
          name: name,
          email: email,
          phone: mobile,
          whatsapp: wa,
          gender: gender,
          address: address,
          college: college.toUpperCase(),
          collegeCity: collegeCity,
          year: yos,
          fb: fb,
          insta: insta,
          twitter: twitter,
          points: 0,
        };
        auth.register(UserData);
        if (referrer !== null && referrer !== "") {
          awardPoint();
        }
        ShowToast("Registered Successfully");
      } else {
        ShowToast("Please Fill all the Required Fields !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.register}>
      <DashboardNavbar />
      <h1 className={styles.registertitle}>ANTARAGNI'24</h1>
      <div className={styles.cover}>
        <h2 className={styles.registertitle2}>Register</h2>
        <div className={styles.registertext}>
          Please Enter the details to register yourself
        </div>
        <div className={styles.details}>
          <div className={styles.field}>
            <label className={styles.labeltext}>Name*</label>
            <input
              className={styles.inputbox}
              type="text"
              required
              value={name}
              placeholder="Enter Your Name"
              onChange={(e) => {
                setName(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Email Id*</label>
            <input
              className={styles.inputbox}
              type="email"
              readOnly
              required
              defaultValue={email}
              placeholder="Enter Your Email Id"
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Mobile Number*</label>
            <input
              className={styles.inputbox}
              placeholder="Enter Your 10 digit mobile number"
              type="number"
              required
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Whatsapp Number*</label>
            <input
              className={styles.inputbox}
              placeholder="Enter your 10 digit WhatsApp number"
              type="number"
              required
              value={wa}
              onChange={(e) => {
                setWa(e.target.value);
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Postal Address*</label>
            <input
              className={styles.inputbox}
              placeholder="Enter Your Postal Address"
              type="text"
              required
              value={address}
              onChange={(e) => {
                setAddress(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Gender*</label>
            <select
              className={styles.inputbox}
              placeholder="Gender"
              type="text"
              required
              value={gender}
              onChange={(e) => {
                setGender(e.target.value.toUpperCase());
              }}
            >
              <option value="" className={styles.option}>
                Gender
              </option>
              <option value="MALE" className={styles.option}>
                MALE
              </option>
              <option value="FEMALE" className={styles.option}>
                FEMALE
              </option>
              <option value="OTHER" className={styles.option}>
                OTHER
              </option>
            </select>
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>College Name*</label>
            <input
              className={styles.inputbox}
              placeholder="Enter Your College Name"
              type="text"
              required
              value={college}
              onChange={(e) => {
                setCollege(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>City of College*</label>
            <input
              className={styles.inputbox}
              placeholder="Enter City of College*"
              type="text"
              required
              value={collegeCity}
              onChange={(e) => {
                setCollegeCity(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Year of Study*</label>
            <select
              className={styles.inputbox}
              placeholder="Year of Study*"
              type="text"
              required
              value={yos}
              onChange={(e) => {
                setYos(e.target.value.toUpperCase());
              }}
            >
              <option value="" className={styles.option}>
                Year of Study
              </option>
              <option value="FIRST YEAR" className={styles.option}>
                FIRST YEAR
              </option>
              <option value="SECOND YEAR" className={styles.option}>
                SECOND YEAR
              </option>
              <option value="THIRD YEAR" className={styles.option}>
                THIRD YEAR
              </option>
              <option value="FOURTH YEAR" className={styles.option}>
                FOURTH YEAR
              </option>
              <option value="5+ YEAR" className={styles.option}>
                5+ YEAR
              </option>
              <option value="CLASS 6" className={styles.option}>
                CLASS 6
              </option>
              <option value="CLASS 7" className={styles.option}>
                CLASS 7
              </option>
              <option value="CLASS 8" className={styles.option}>
                CLASS 8
              </option>
              <option value="CLASS 9" className={styles.option}>
                CLASS 9
              </option>
              <option value="CLASS 10" className={styles.option}>
                CLASS 10
              </option>
              <option value="CLASS 11" className={styles.option}>
                CLASS 11
              </option>
              <option value="CLASS 12" className={styles.option}>
                CLASS 12
              </option>
            </select>
          </div>

          <div className={styles.field}>
            <label className={styles.labeltext}>Facebook Profile</label>
            <input
              className={styles.inputbox}
              placeholder="Link to Your Facebook Profile Link"
              type="url"
              value={fb}
              onChange={(e) => {
                setfb(e.target.value);
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Instagram Profile</label>
            <input
              className={styles.inputbox}
              placeholder="Enter Your Instagram Profile Link"
              type="url"
              value={insta}
              onChange={(e) => {
                setInsta(e.target.value);
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Twitter Profile</label>
            <input
              className={styles.inputbox}
              placeholder="Enter Your Twitter Profile Link"
              type="url"
              value={twitter}
              onChange={(e) => {
                setTwitter(e.target.value);
              }}
            />
          </div>
          <div className={styles.field}>
            <label className={styles.labeltext}>Referrer CA Id</label>
            <input
              className={styles.inputbox}
              placeholder="Enter the CA Id of the Referrer CA"
              type="text"
              value={referrer}
              onChange={(e) => {
                setReferrer(e.target.value.toUpperCase());
              }}
            />
          </div>

          {/* {!sendingOtp ? (
            <div className={styles.field}>
              <label className={styles.labeltext}>Mobile Number*</label>
              <span className={styles.pair}>
                <input
                  className={styles.inputbox}
                  placeholder="Enter Your 10 digit mobile number"
                  type="number"
                  required
                  readOnly={phoneVerified}
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                />
                {!phoneVerified && (
                  <button
                    id="otpBtn"
                    className={btnStyles.btn}
                    onClick={() => {
                      if (mobile.length === 10) {
                        setSendingOtp(true);
                        firebaseLinkPhone(`+91${mobile}`, "otpBtn");
                      } else {
                        ShowToast("Enter valid 10 digits Mobile Number !");
                      }
                    }}
                  >
                    Send OTP
                  </button>
                )}
              </span>
            </div>
          ) : (
            <div className={styles.field}>
              <label className={styles.labeltext}>OTP*</label>
              <span className={styles.pair}>
                <input
                  className={styles.inputbox}
                  placeholder="Enter 6 digit OTP"
                  type="number"
                  required
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
                <button
                  id="otpBtn"
                  className={btnStyles.btn}
                  onClick={async () => {
                    if (otp.length === 6) {
                      try {
                        let user = await firebaseVerifyOTP(otp);
                        if (
                          user.phoneNumber !== null &&
                          user.phoneNumber !== ""
                        ) {
                          setPhoneVerified(true);
                        }
                        setSendingOtp(false);
                      } catch (error) {
                        console.log(error);
                      }
                    } else {
                      ShowToast("Enter valid 6 digits OTP");
                    }
                  }}
                >
                  Verify OTP
                </button>
                <button
                  id="otpBtn"
                  className={btnStyles.btn}
                  onClick={async () => {
                    setSendingOtp(false);
                  }}
                >
                  Resend OTP
                </button>
              </span>
            </div>
          )} */}
        </div>
        <button className={btnStyles.btn} onClick={register}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Register;
