import styles from "../css/ideas.module.css";
import { addData } from "../config/firebaseFirestore";
import { useAuth } from "../hooks";
import { useState } from "react";
import { ShowToast } from "../components";

const IdeasPage = () => {
  const auth = useAuth();
  const [idea, setIdea] = useState("");
  // Register Function
  const submit = async () => {
    try {
      if (idea != "") {
        let data = {
          id: auth.user.details.id,
          name: auth.user.details.name,
          email: auth.user.details.email,
          phone: auth.user.details.phone,
          idea: idea,
          college: auth.user.details.college,
          collegeCity: auth.user.details.collegeCity,
        };
        addData("CAsIdeas24", data);
        ShowToast("Idea Submitted Successfully");
        setIdea("");
      } else {
        ShowToast("Please write your idea !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.ideas}>
      <div className={styles.wrapper}>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="idea">
            Idea
          </label>
          <textarea
            className={styles.input}
            id="idea"
            required
            value={idea}
            placeholder="Write your ideas here ..."
            onChange={(e) => {
              setIdea(e.target.value);
            }}
          />
        </div>
        <button
          className={styles.btn}
          onClick={(e) => {
            submit();
          }}
        >
          Submit Idea
        </button>
      </div>
    </div>
  );
};

export default IdeasPage;
