import styles from "../css/profile.module.css";
import { useAuth } from "../hooks";

const ProfilePage = () => {
  const auth = useAuth();
  return (
    <div className={styles.profilePage}>
      <div className={styles.wrapper}>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="caId">
            CA Id
          </label>
          <input
            className={styles.input}
            id="caId"
            type="text"
            readOnly
            defaultValue={auth.user.details.id}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="name">
            Name
          </label>
          <input
            className={styles.input}
            id="name"
            type="text"
            readOnly
            defaultValue={auth.user.details.name}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="mobile">
            Mobile Number
          </label>
          <input
            className={styles.input}
            id="mobile"
            type="text"
            readOnly
            defaultValue={auth.user.details.phone}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="email">
            Email Id
          </label>
          <input
            className={styles.input}
            id="email"
            type="email"
            readOnly
            defaultValue={auth.user.details.email}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="wa">
            Whatsapp Number
          </label>
          <input
            className={styles.input}
            id="wq"
            type="tel"
            readOnly
            defaultValue={auth.user.details.whatsapp}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="add">
            Postal Address
          </label>
          <input
            className={styles.input}
            id="add"
            type="text"
            readOnly
            defaultValue={auth.user.details.address}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="college">
            College
          </label>
          <input
            className={styles.input}
            id="college"
            type="text"
            readOnly
            defaultValue={auth.user.details.college}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="colCity">
            City of College
          </label>
          <input
            className={styles.input}
            id="colCity"
            type="text"
            readOnly
            defaultValue={auth.user.details.collegeCity}
          />
        </div>
        <div className={styles.textInput}>
          <label className={styles.label} htmlFor="yos">
            Year of Study
          </label>
          <input
            className={styles.input}
            id="yos"
            type="text"
            readOnly
            defaultValue={auth.user.details.year}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
