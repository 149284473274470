import styles from "../css/contactus.module.css";
import { ContactCard } from "../components";

const ContactUs = ({ data }) => {
  return (
    <div className={styles.contact} id="contacts">
      <h1>CONTACT US</h1>
      <div className={styles.cardsContainer}>
        {data.map((contact) => {
          return <ContactCard key={contact.Id} contact={contact} />;
        })}
      </div>
    </div>
  );
};

export default ContactUs;
