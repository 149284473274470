import { useAuth } from "../hooks";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styles from "../css/dashboard.module.css";
import { DashboardNavbar } from "../components";
import { IdeasPage, LeaderboardPage, TasksPage, ProfilePage } from "./";

const Dashboard = () => {
  const auth = useAuth();
  return (
    <div className={styles.dashboard}>
      <DashboardNavbar />
      <h1 className={styles.registertitle}>ANTARAGNI'24</h1>
      <Tabs className={styles.tabs}>
        <TabList className={styles.tabList}>
          {/* <Tab>Dashboard</Tab> */}
          <Tab>Profile</Tab>
          <Tab>Leaderboard</Tab>
          <Tab>Tasks</Tab>
          <Tab>Ideas</Tab>
        </TabList>

        {/* <TabPanel className={styles.tabPanel}>Dashboard</TabPanel> */}
        <TabPanel className={styles.tabPanel}>
          <ProfilePage />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <LeaderboardPage />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <TasksPage />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <IdeasPage />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Dashboard;
