import styles from "../css/app.module.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Bounce } from "react-toastify";
import { useAuth } from "../hooks";
import { Loader } from "./";
import { LoginPage, LandingPage, Dashboard, Register } from "../pages";
const App = () => {
  const auth = useAuth();
  return auth.loading ? (
    <Loader />
  ) : (
    <div className={styles.app}>
      <ToastContainer transition={Bounce} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/login"
            element={
              !auth.user ? (
                <LoginPage />
              ) : !auth.user.details ? (
                <Navigate replace to="/register" />
              ) : (
                <Navigate replace to="/dashboard" />
              )
            }
          ></Route>
          <Route
            path="/register"
            element={
              !auth.user ? (
                <Navigate replace to="/login" />
              ) : !auth.user.details ? (
                <Register />
              ) : (
                <Navigate replace to="/dashboard" />
              )
            }
          ></Route>
          <Route
            path="/dashboard"
            element={
              !auth.user ? (
                <Navigate replace to="/login" />
              ) : !auth.user.details ? (
                <Navigate replace to="/register" />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
